<section class="section more-proyects" id="skills">
  <div class="container">
    <div class="section-box">
      <div class="">
        <h3 class="section-title">
          <span class="n-section-title">04.</span>
          I'm skilled at
        </h3>
      </div>
      <div class="mt-5">
        <div class="row p-0">
          <div
            *ngFor="let skill of JsonData.skills; let i = index"
            class="col-12 col-md-6 col-lg-3 col-xl-2 proyect-col"

          >
            <div class="skill-box">
              <div class="text-center">
                <img [src]="skill.LogoImagesLink" width="55"/>
                <p class="mt-2 font-weight-bold">{{ skill.Title }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
