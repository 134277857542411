<section class="section banner">
  <!-- Background animtion-->
  <div class="background">
    <div class="cube"><img src="assets/bglogo/angular.png" height="312" width="312"/></div>
    <div class="cube"><img src="assets/bglogo/android.png" height="312" width="312"/></div>
    <div class="cube"><img src="assets/bglogo/angular_meterial.png" height="312" width="312"/></div>
    <div class="cube"><img src="assets/bglogo/java.png" height="312" width="312"/></div>
    <div class="cube"><img src="assets/bglogo/spring.png" height="312" width="312"/></div>
    <div class="cube"><img src="assets/bglogo/scss.png" height="312" width="312"/></div>
    <div class="cube"><img src="assets/bglogo/react_native.png" height="312" width="312"/></div>
    <div class="cube"><img src="assets/bglogo/ts.png" height="312" width="312"/></div>
  </div>
  <div [@bannerTrigger] class="container">
    <div class="section-box-banner">
      <div class="content">
        <div>
          <h1>Hello! This is</h1>
        </div>
        <div class="banner-title">
          <h2>{{ JsonData.personal.name }}</h2>
          <h4>{{ JsonData.personal.profession }}</h4>
        </div>
        <div class="banner-description">
          <p [innerHTML]="JsonData.personal.aboutMe" class="mt-4"></p>
        </div>
      </div>
      <div class="div-btn-banner">
        <a
          class="main-btn"
          href="mailto:{{ JsonData.personal.email }}"
          target="_black"
        >
          Get in touch
        </a>
      </div>
    </div>
  </div>
</section>
