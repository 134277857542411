<nav
  [@animateMenu]
  [class.nav-shadow]="this.pageYPosition > 0"
  class="navbar main-navbar on-top"
>
  <div class="container">
    <a [routerLink]="'inicio'" class="navbar-brand text-light">
      <img alt="logo" src="assets/images/coding_gif.gif" width="70" height="50" />
    </a>
    <ul #nav="ngbNav" class="menu-ul" ngbNav>
      <li ngbNavItem>
        <a (click)="scroll('about')" ngbNavLink
          ><span class="nav-number text-bold"><</span>
          <span class="underline nav-text"> About me </span
          ><span class="nav-number text-bold">/></span></a
        >
        <ng-template ngbNavContent>First content</ng-template>
      </li>
      <li ngbNavItem>
        <a (click)="scroll('jobs')" ngbNavLink
          ><span class="nav-number text-bold"><</span>
          <span class="underline nav-text"> Experience</span
          ><span class="nav-number text-bold">/></span></a
        >
        <ng-template ngbNavContent>Second content</ng-template>
      </li>
      <li ngbNavItem>
        <a (click)="scroll('proyects')" ngbNavLink
          ><span class="nav-number text-bold"><</span>
          <span class="underline nav-text"> Projects</span
          ><span class="nav-number text-bold">/></span></a
        >
        <ng-template ngbNavContent>Second content</ng-template>
      </li>
      <li ngbNavItem>
        <a (click)="scroll('skills')" ngbNavLink
          ><span class="nav-number text-bold"><</span>
          <span class="underline nav-text"> Skills</span
          ><span class="nav-number text-bold">/></span></a
        >
        <ng-template ngbNavContent>Second content</ng-template>
      </li>
      <li ngbNavItem>
        <a (click)="redirectBlogSite()" ngbNavLink
        ><span class="nav-number text-bold"><</span>
          <span class="underline nav-text"> Blog</span
          ><span class="nav-number text-bold">/></span></a
        >
        <ng-template ngbNavContent>Second content</ng-template>
      </li>
      <li ngbNavItem>
        <a (click)="scroll('contact')" ngbNavLink
          ><span class="nav-number text-bold"><</span>
          <span class="underline nav-text"> Contact</span
          ><span class="nav-number text-bold">/></span></a
        >
        <ng-template ngbNavContent>Second content</ng-template>
      </li>
      <li ngbNavItem>
        <a (click)="downloadCV()" class="main-btn cv-btn" ngbNavLink>
          Resume
        </a>
      </li>
    </ul>
    <div class="menu-wrapper">
      <div
        (click)="responsiveMenuVisible = !responsiveMenuVisible"
        [class.animate]="responsiveMenuVisible"
        class="hamburger-menu"
      ></div>
    </div>
    <div
      [ngStyle]="{ 'pointer-events': !responsiveMenuVisible ? 'none' : '' }"
      class="menu-responsive"
    >
      <aside
        [class.aside-show]="responsiveMenuVisible"
        [class.nav-shadow]="this.pageYPosition > 0"
        class="on-top"
      >
        <nav>
          <ol>
            <li>
              <a (click)="scroll('about')">
                <span class="nav-number text-bold"><</span>
                <span class="underline nav-text"> About me </span
                ><span class="nav-number text-bold">/></span>
              </a>
            </li>
            <li>
              <a (click)="scroll('jobs')">
                <span class="nav-number text-bold"><</span>
                <span class="underline nav-text"> Experience</span
                ><span class="nav-number text-bold">/></span>
              </a>
            </li>
            <li>
              <a (click)="scroll('proyects')">
                <span class="nav-number text-bold"><</span>
                <span class="underline nav-text"> Projects</span
                ><span class="nav-number text-bold">/></span>
              </a>
            </li>
            <li>
              <a (click)="scroll('skills')">
                <span class="nav-number text-bold"><</span>
                <span class="underline nav-text"> Skills</span
                ><span class="nav-number text-bold">/></span>
              </a>
            </li>
            <li>
              <a (click)="scroll('proyects')">
                <span class="nav-number text-bold"><</span>
                <span class="underline nav-text"> Contact</span
                ><span class="nav-number text-bold">/></span>
              </a>
            </li>
            <li>
              <a (click)="downloadCV()" class="main-btn cv-btn"> Resume </a>
            </li>
          </ol>
        </nav>
      </aside>
    </div>
  </div>
</nav>
