<section class="section about" id="about">
  <div class="container">
    <div class="section-box">
      <div class="about-title" data-aos="fade-up">
        <h3 class="section-title">
          <span class="n-section-title">01.</span>
          About me
        </h3>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 mb-4 mb-md-0">
          <div class="about-description">
            <p data-aos="fade-up" data-aos-duration="1000">
              "I strive to improve my skills every day. I have experience in writing unit tests, performing continuous integration, and handling backend development roles using Spring Boot and MySQL. I have successfully led teams with empathy, motivated colleagues, and collaboratively set plans and goals.
              My skills also include implementing system improvements, optimizing websites, standardizing patterns, and proposing and executing ideas that add value to the projects I work on. Some of the technologies I have trained in and worked with include (but are not limited to).
              "
            </p>
            <!--            <ul class="skills-list" data-aos="fade-up">-->
            <!--              <li-->
            <!--                class="skill-element"-->
            <!--                data-aos="fade-up"-->
            <!--                data-aos-duration="1000"-->
            <!--              >-->
            <!--                <span class="underline">Angular 11</span>-->
            <!--              </li>-->
            <!--              <li-->
            <!--                class="skill-element"-->
            <!--                data-aos="fade-up"-->
            <!--                data-aos-duration="1000"-->
            <!--              >-->
            <!--                <span class="underline">Java 11 </span>-->
            <!--              </li>-->
            <!--              <li-->
            <!--                class="skill-element"-->
            <!--                data-aos="fade-up"-->
            <!--                data-aos-duration="1000"-->
            <!--              >-->
            <!--                <span class="underline">PostgreSQL &amp; MySQL (DB)</span>-->
            <!--              </li>-->
            <!--              <li-->
            <!--                class="skill-element"-->
            <!--                data-aos="fade-up"-->
            <!--                data-aos-duration="1000"-->
            <!--              >-->
            <!--                <span class="underline">Springboot </span>-->
            <!--              </li>-->
            <!--              <li-->
            <!--                class="skill-element"-->
            <!--                data-aos="fade-up"-->
            <!--                data-aos-duration="1000"-->
            <!--              >-->
            <!--                <span class="underline">React Native </span>-->
            <!--              </li>-->
            <!--              <li-->
            <!--                class="skill-element"-->
            <!--                data-aos="fade-up"-->
            <!--                data-aos-duration="1000"-->
            <!--              >-->
            <!--                <span class="underline">Jenkins (CI/CD)</span>-->
            <!--              </li>-->
            <!--              <li-->
            <!--                class="skill-element"-->
            <!--                data-aos="fade-up"-->
            <!--                data-aos-duration="1000"-->
            <!--              >-->
            <!--                <span class="underline">Jasmine / Karma (TDD) </span>-->
            <!--              </li>-->
            <!--              <li-->
            <!--                class="skill-element"-->
            <!--                data-aos="fade-up"-->
            <!--                data-aos-duration="1000"-->
            <!--              >-->
            <!--                <span class="underline">Docker (CI/CD)</span>-->
            <!--              </li>-->
            <!--            </ul>-->
          </div>
        </div>
        <div
          class="col-12 col-md-6 mt-4 mt-md-0 text-center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div class="about-img-container">
            <img
              alt="Moniruzzaman rony"
              class="rounded"
              height="280"
              src="assets/images/me.jpg"
              width="230"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
