<section class="section contact" id="contact">
  <div class="container">
    <div class="section-box">
      <div class="mb-4" data-aos="fade-up" data-aos-duration="1000">
        <h3 class="contact-pre-title big-subtitle">05. What’s Next</h3>
        <h4 class="e-font contact-title big-title">Get in touch!</h4>
      </div>
      <p data-aos="fade-up" data-aos-duration="1000">
        I will be happy to receive your contact if you want to talk about new
        opportunities. Write me, and I will reply as soon as I see your message.
        Simple one-page design that features a profile letter with basic
        information about a person.
      </p>
      <div class="mt-5">
        <a
          class="main-btn"
          data-aos="fade-up"
          data-aos-duration="1000"
          href="mailto:{{ JsonData.personal.email }}"
          target="_blank"
        >
          "Say Hello"
        </a>
      </div>
    </div>
  </div>
</section>
